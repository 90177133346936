import {
  Box,
  chakra,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Link,
} from '@chakra-ui/react';
import React, {FC} from 'react';
import {Link as RouterLink} from 'react-router-dom';

import {routes} from 'src/router';
import {ReactComponent as Logo} from '../../assets/icons/logo.svg';
import {AccountInfo} from './account-info';

const StyledLogo = chakra(Logo);

interface SideBarMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

interface SideBarMenuLinksProps {
  route: string;
  label: string;
}

const SideBarMenu: FC<SideBarMenuProps> = ({isOpen, onClose}) => {
  return (
    <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton
          _focus={{outline: 'none'}}
          _active={{bgColor: 'transparent'}}
          _hover={{bgColor: 'transparent'}}
        />
        <DrawerHeader borderBottomWidth="1px">
          <Link href={routes.Conversations.route} onClick={onClose}>
            <StyledLogo w="60px" h="auto" mr={{base: 4, md: 9}} />
          </Link>
        </DrawerHeader>
        <DrawerFooter mb={5} mt='auto'>
          <Flex justify="start" w="100%">
            <AccountInfo isFullWidth />
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default SideBarMenu;
