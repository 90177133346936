import {firebaseFunctions} from "@workspace/firebase-app";
import {Box, Divider, Flex, Input, Select, Text, useDisclosure} from "@chakra-ui/react";
import moment from "moment-timezone";
import {FC, useEffect, useState} from "react";
import {DsmSolidButton} from "../../../components/buttons";
import {useAppSelector} from "../../../app-hooks";
import {ChangePasswordModal} from "./ChangePasswordModal";
import {Button, Heading} from "@workspace/ui";
import {useMutation} from "@tanstack/react-query";
import {setTimezoneOnCall} from "../user-service";

export const AccountDetails: FC = () => {
  const changePasswordModal = useDisclosure();
  const user = useAppSelector((state) => state.user.user);

  const [timezoneName, setTimezoneName] = useState(
    () => user?.timezone || moment.tz.guess(),
  );
  const [firstName, setFirstName] = useState(user?.firstName ?? "");
  const [lastName, setLastName] = useState(user?.lastName ?? "");

  useEffect(() => setTimezoneName(user?.timezone || moment.tz.guess()), [user?.timezone]);

  const saveDetailsMutation = useMutation({
    mutationFn: async () => {
      await Promise.all([
        firebaseFunctions.httpsCallable("changeNameOnCall")({
          firstName,
          lastName,
        }),
        setTimezoneOnCall({timezone: timezoneName})
      ])
    }
  })


  if (!user) return null;

  return (
    <>
      <Flex gap="4" fontFamily="Inter">
        <Box width="40%">
          <Heading variant="h3" color="gray.900" mb="2">
            Account details
          </Heading>
          <Text color="gray.600" fontSize="14px" lineHeight="20px">
            Manage your account credentials and other details.
          </Text>
        </Box>
        <Box
          p="8"
          width="60%"
          borderRadius="md"
          border="1px solid rgba(0, 0, 0, 0.08)"
          boxShadow="0px 5px 12px rgba(0, 0, 0, 0.05)"
        >
          <Flex mb="6" gap="4">
            <Box w="50%">
              <Text color="gray.900" fontSize="14px" fontWeight="600" mb="1">
                First name
              </Text>
              <Input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value.trimLeft())}
              />
            </Box>
            <Box w="50%">
              <Text color="gray.900" fontSize="14px" fontWeight="600" mb="1">
                Last name
              </Text>
              <Input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value.trimLeft())}
              />
            </Box>
          </Flex>
          <Box mb="6">
            <Text color="gray.900" fontSize="14px" fontWeight="600" mb="1">
              Email
            </Text>
            <Input
              color="gray.500"
              fontSize="base"
              background="gray.50"
              type="text"
              _hover={{}}
              borderColor="gray.200"
              borderWidth="1px"
              borderStyle="solid"
              value={user.email}
              readOnly
            />
          </Box>
          <Box>
            <Text color="gray.900" fontSize="14px" fontWeight="600" mb="1">
              Timezone
            </Text>
            <Select
              fontSize="baseMinor"
              mb="6"
              value={timezoneName}
              onChange={(e) => setTimezoneName(e.target.value)}
            >
              {moment.tz.names().map((timezoneName) => (
                <option key={timezoneName} value={timezoneName}>
                  {timezoneName.replace(/_/g, " ")}
                </option>
              ))}
            </Select>
            <DsmSolidButton onClick={() => saveDetailsMutation.mutate()} isDisabled={saveDetailsMutation.isPending}>
              Save changes
            </DsmSolidButton>
          </Box>
          <Divider my="6" />
          <Box>
            <Text color="gray.900" fontSize="md" fontWeight="bold" mb="6">
              Password
            </Text>
            <Button
              size="sm"
              fontWeight="500"
              variant="bordered"
              color="black"
              background="white"
              onClick={changePasswordModal.onOpen}
            >
              Change password
            </Button>
          </Box>
        </Box>
      </Flex>
      <ChangePasswordModal
        isOpen={changePasswordModal.isOpen}
        onClose={changePasswordModal.onClose}
      />
    </>
  );
};
