import React from "react";
import {chakra, Flex, Text} from "@chakra-ui/react";
import {Icon} from "@workspace/ui";
import {useFirebaseUser, useUserCredits} from "@workspace/react";
import {Link} from "react-router-dom";

function formatNumber(number = 0) {
  return new Intl.NumberFormat('en-US').format(number);
}

const CreditsText = chakra(Text, {
  fontSize: 14,
  fontWeight: 500,
  color: 'gray.900'
})

const UserCredits = () => {
  const {userInfo} = useFirebaseUser();
  const userCredits = useUserCredits(userInfo?.uid)

  return (
    <Link to='/credits'>
      <Flex gap={2} align='center'>
        <Icon name='credits' style={{position: 'relative', top: 1}} color={(userCredits.data || 0) > 0 ? '#FF9900' : '#7F7F7F'} />
        <CreditsText>{formatNumber(userCredits.data || 0)} credits</CreditsText>
      </Flex>
    </Link>
  );
};

export default UserCredits;
