"use strict";
exports.__esModule = true;
exports.isCreditsCheckoutSessionOutput = exports.isCreditsCheckoutSessionInput = void 0;
var type_utils_1 = require("@workspace/type-utils");
exports.isCreditsCheckoutSessionInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var credits = _a.credits;
    return (0, type_utils_1.isNonNegativeInteger)(credits);
});
exports.isCreditsCheckoutSessionOutput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var url = _a.url;
    return (0, type_utils_1.isNonEmptyString)(url);
});
