import {Button, chakra, Divider, Flex, Icon, Spacer, useBreakpointValue, useDisclosure} from "@chakra-ui/react";
import {colorHex} from "@workspace/ui";
import {FC, ReactNode, useMemo} from "react";
import {IoHelpCircle, IoMenuOutline} from "react-icons/io5";
import {Link} from "react-router-dom";

import {ReactComponent as Logo} from "../../assets/icons/logo.svg";
import {MAX_MOBILE_RESOLUTION} from "../../helpers/constants";
import useWindowWidth from "../../helpers/hooks/use-window-width";
import routes from "../../router/routes";

import {AccountInfo} from "./account-info";
import {NavbarRoute} from "./nav-menu";
import SideBarMenu from "./side-bar-menu";
import {NavLinks} from "./nav-links";
import UserCredits from "./user-credits";

const StyledLogo = chakra(Logo);
const StyledIoHelpCircle = chakra(IoHelpCircle);

interface TopNavbarProps {
  activeRoute?: NavbarRoute;
  showTrialInfo?: boolean;
  middleContent?: ReactNode;
}

export const TopNavbar: FC<TopNavbarProps> = ({
                                                activeRoute,
                                                showTrialInfo,
                                                middleContent,
                                              }) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  const order = useBreakpointValue({base: 6, sm: 0});
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < MAX_MOBILE_RESOLUTION;

  const sideBarMenu = useMemo(
    () => (
      <>
        {isMobile && (
          <Button
            onClick={onOpen}
            color="black"
            variant="link"
            _focus={{outline: "none"}}
            _active={{bgColor: "transparent"}}
            _hover={{bgColor: "transparent"}}
          >
            <Icon as={IoMenuOutline} fontSize="2xl" />
          </Button>
        )}
        {isMobile ? <SideBarMenu isOpen={isOpen} onClose={onClose} /> : (
          <Flex gap={4} align="center">
            <UserCredits />
            <Divider h="20px" orientation="vertical" />
            <AccountInfo />
          </Flex>
        )}
      </>
    ),
    [isMobile, isOpen, onClose, onOpen],
  );

  return (
    <Flex
      position="relative"
      zIndex="10"
      as="nav"
      px="6"
      py="2"
      bg="white"
      borderColor={colorHex.alpha05}
      borderBottomWidth="1px"
      height="64px"
      align="center"
    >
      <Flex
        align="center"
        justify={middleContent ? "" : "space-between"}
        wrap="wrap"
        maxWidth="1440px"
        mx="auto"
        flexGrow="1"
      >
        {isMobile && (
          <Button
            onClick={onOpen}
            color="black"
            variant="link"
            _focus={{outline: "none"}}
            _active={{bgColor: "transparent"}}
            _hover={{bgColor: "transparent"}}
          >
            <Icon as={IoMenuOutline} fontSize="2xl" />
          </Button>
        )}

        <Link to={routes.Integrations.route}>
          <StyledLogo
            w={isMobile ? "102px" : "119px"}
            h="auto"
            mr={{base: "16px", md: "38px"}}
          />
        </Link>

        {!isMobile && !middleContent && <Spacer />}

        {
          !middleContent ? (
            <NavLinks />
          ) : middleContent
        }

        {sideBarMenu}
      </Flex>
    </Flex>
  );
};
