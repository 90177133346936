import {isNonEmptyString, NonEmptyString, objectTypeGuard} from "@workspace/type-utils";


export type User = {
  email: NonEmptyString;
  firstName?: string;
  lastName?: string;
  credits: number;
  id: string;
  hasDiscovery: boolean;
};

export const isUser = objectTypeGuard<User>(({email, id}) =>
  (email === undefined ? true : isNonEmptyString(email)) && isNonEmptyString(id),
);
