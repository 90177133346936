import React, {ChangeEventHandler, useState} from "react";
import InnerAppLayout from "../../components/inner-app-layout";
import {Box, chakra, Flex, InputGroup, InputRightElement, Spinner, Text} from "@chakra-ui/react";
import {Button, Icon, Input, Tab, TabList, Tabs} from "@workspace/ui";
import CreditCard from "../../components/credits/CreditCard";
import {getCreditsDiscount, getCreditsTotalPrice} from "@workspace/models";
import {firebaseFunctions, firebaseFunctions as functions} from "@workspace/firebase-app";
import {cloudFunctionName} from "@workspace/firebase-definitions";
import {isCloudFunctionOutput} from "@workspace/firebase-datamodel";
import {useMutation} from "@tanstack/react-query";
import {useUserCredits} from "@workspace/react/queries/useUserCredits";
import {useFirebaseUser} from "@workspace/react";

function formatNumber(number = 0) {
  return new Intl.NumberFormat('en-US').format(number);
}

const CreditsGrid = chakra(Box, {
  baseStyle: {
    display: 'grid',
    gridTemplateColumns: '1.6fr 1fr',
    columnGap: '56px',
    marginTop: 16,
    padding: '0 21%'
  }
})

const ValueRow = chakra(Flex, {
  baseStyle: {
    borderBottom: '1px solid #0000001A',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px'
  }
})

const DiscountRow = chakra(Flex, {
  baseStyle: {
    borderBottom: '1px solid #0000001A',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4px 0'
  }
})

const InfoCard = chakra(Box, {
  baseStyle: {
    padding: '24px',
    borderRadius: '16px',
    background: '#F8F8F8'
  },
  variants: {
    noBorder: {
      border: 'none'
    }
  }
})

const AddCreditsPage = () => {
  const [activeCreditsTab, setActiveCreditsTab] = useState<number>(0);
  const [inputCredits, setInputCredits] = useState(0);

  const {userInfo} = useFirebaseUser();
  const userCredits = useUserCredits(userInfo?.uid)

  const creditsMutation = useMutation({
    mutationFn: async () => {
      if(inputCredits < 10) throw new Error('Minimum of 10 credits');

      const response = await firebaseFunctions.httpsCallable(cloudFunctionName.createCreditsCheckoutSession)({
        credits: inputCredits
      });

      if(isCloudFunctionOutput.createCreditsCheckoutSession(response.data)) {
        return response.data;
      }

      throw new Error('Invalid response')
    },
    onSuccess: ({ url }) => {
      window.location.href = url
    },
    onError: error => {
      console.error(error)
      alert('Something went wrong')
    }
  })

  const createBillingPortalSession = useMutation({
    mutationFn: async () => {
      const {data: billingPortalSession} = await functions.httpsCallable(
        cloudFunctionName.createBillingPortalSession,
      )();
      if (isCloudFunctionOutput.createBillingPortalSession(billingPortalSession))
        window.open(billingPortalSession.url, "_self");

      return
    }
  })

  const onChangeInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    const number = Number.isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value);

    setInputCredits(Math.max(number, 0))
  }

  const renderActiveTab = () => {
    if(activeCreditsTab === 0){
      return (
        <Flex style={{flexDirection: 'column'}} gap={4}>
          <CreditCard credits={100}/>
          <CreditCard credits={1000}/>
          <CreditCard credits={2000}/>
        </Flex>
      )
    }

    return (
      <Box>
        <Text fontSize={14} fontWeight={600} color='gray.900' mb={2}>
          How many credits do you need?
        </Text>
        <InputGroup mb={1}>
          <Input value={inputCredits} onChange={onChangeInput}/>
          <InputRightElement>
            <Icon name='credits' color='#FF9900' />
          </InputRightElement>
        </InputGroup>
        <small style={{marginBottom: '24px', fontSize: 12, color: '#657380'}}>Minimum of 10 credits required</small>
        <ValueRow>
          <Text fontSize={14}>
            Credits Cost
          </Text>
          <Text fontSize={14}>
            $ {inputCredits/10}
          </Text>
        </ValueRow>
        <ValueRow>
          <Text fontSize={14}>
            Discount
          </Text>
          <Text fontSize={14}>
            {getCreditsDiscount(inputCredits)}%
          </Text>
        </ValueRow>
        <ValueRow>
          <Text fontWeight={700} fontSize={14}>
            Total USD
          </Text>
          <Text fontWeight={700} fontSize={14}>
            $ {getCreditsTotalPrice(inputCredits)}
          </Text>
        </ValueRow>
        <Button
          onClick={() => creditsMutation.mutate()}
          disabled={inputCredits < 10 || creditsMutation.isPending}
          variant="primary"
          mt={6}
        >
          <Spinner size='xs' hidden={!creditsMutation.isPending} mr={2}/>
          <span>
            Buy credits
          </span>
        </Button>
      </Box>
    )
  }

  return (
    <InnerAppLayout>
      <CreditsGrid>
        <Box>
          <Text fontFamily="Circular" fontSize={24} fontWeight={700} color='gray.900' mb={2}>
            Add more credits
          </Text>
          <Text fontSize={14} color='black' fontWeight={400} mb={6}>
            Credits are used to send messages. <br/>
            1 credit = 1 message. Buy in bulk for discounts.
          </Text>
          <Tabs mb={4} variant="outlined" onChange={setActiveCreditsTab}>
            <TabList>
            <Tab>
              Quick Buy
            </Tab>
            <Tab>
              Custom
            </Tab>
            </TabList>
          </Tabs>
          {renderActiveTab()}
        </Box>
        <Flex style={{flexDirection: 'column'}} gap={4}>
          <InfoCard>
            <Text fontSize={14} mb={2}>
              Current balance
            </Text>
            <Flex align='center'>
              <Icon color={(userCredits.data || 0) > 0 ? '#FF9900' : '#7F7F7F'} name='credits' size={20} />
              <Text fontFamily="Circular" ml={2} fontSize={24} fontWeight={700} color='gray.900'>
                {formatNumber(userCredits.data)} credits
              </Text>
            </Flex>
          </InfoCard>
          <InfoCard>
            <Text fontWeight={700} fontSize={14} mb={2}>
              Bulk discount
            </Text>
            <DiscountRow>
              <Text fontSize={14}>
                100+ credits
              </Text>
              <Text fontSize={12} opacity={0.5}>
                10%
              </Text>
            </DiscountRow>
            <DiscountRow>
              <Text fontSize={14}>
                500+ credits
              </Text>
              <Text fontSize={12} opacity={0.5}>
                15%
              </Text>
            </DiscountRow>
            <DiscountRow>
              <Text fontSize={14}>
                1000+ credits
              </Text>
              <Text fontSize={12} opacity={0.5}>
                20%
              </Text>
            </DiscountRow>
            <DiscountRow variant="noBorder">
              <Text fontSize={14}>
                2000+ credits
              </Text>
              <Text fontSize={12} opacity={0.5}>
                30%
              </Text>
            </DiscountRow>
          </InfoCard>
          <InfoCard>
            <Text fontSize={14} fontWeight={700} mb={4}>
              FAQs
            </Text>
            <Box mb={4}>
              <Text fontSize={14} fontWeight={700}>
                How much do credits cost?
              </Text>
              <Text fontSize={14}>
                1 credit = $0.1 USD
              </Text>
            </Box>
            <Box mb={4}>
              <Text fontSize={14} fontWeight={700}>
                Do credits expire?
              </Text>
              <Text fontSize={14}>
                No. Credits purchased have no expiration date.
              </Text>
            </Box>
            <Box mb={4}>
              <Text fontSize={14} fontWeight={700}>
                Where can I see invoices?
              </Text>
              <Text fontSize={14}>
                You can access all your invoices through
                <Text onClick={() => createBillingPortalSession.mutate()} cursor="pointer" decoration="underline">
                  <Spinner size='xs' hidden={!createBillingPortalSession.isPending}/> Stripe Customer Portal.
                </Text>
              </Text>
            </Box>
          </InfoCard>
        </Flex>
      </CreditsGrid>
    </InnerAppLayout>
  );
};

export default AddCreditsPage;
