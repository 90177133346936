import {Grid, GridItem, Box, Flex, Text} from "@chakra-ui/react";
import {FC, PropsWithChildren, ReactNode} from "react";

import {NavbarRoute} from "./top-navbar/nav-menu";
import {TopNavbar} from "./top-navbar/top-navbar";
import useWindowWidth from "../helpers/hooks/use-window-width";
import {MAX_MOBILE_RESOLUTION} from "../helpers/constants";
import {ReactComponent as SwitchToDesktopIcon} from "../assets/icons/switch-to-desktop.svg";
import {Heading} from "@workspace/ui";

const InnerAppLayout: FC<
  PropsWithChildren<{
    activeRoute?: NavbarRoute;
    showTrialInfo?: boolean;
    navbarMiddleContent?: ReactNode;
    shouldRenderSwitchToDesktop?: boolean;
  }>
> = ({
  activeRoute,
  showTrialInfo,
  children,
  navbarMiddleContent,
  shouldRenderSwitchToDesktop = true,
}) => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < MAX_MOBILE_RESOLUTION;

  const renderChildren = () => {
    if (!isMobile || !shouldRenderSwitchToDesktop) return children;

    return (
      <Box h="full" pt="92px" px="16px" bg="#F4F4F9">
        <Flex
          boxShadow="0px 30px 84px rgba(10, 9, 68, 0.08), 0px 8px 32px rgba(10, 9, 68, 0.07), 0px 3px 14px rgba(10, 9, 68, 0.03), 0px 1px 3px rgba(10, 9, 68, 0.13)"
          bg="white"
          borderRadius="16px"
          flexDirection="column"
          alignItems="center"
          py={10}
          px={6}
          maxWidth="400px"
          mx="auto"
        >
          <SwitchToDesktopIcon />
          <Heading variant="h2" color="gray.900" mb={4} mt={6}>
            Switch to desktop
          </Heading>
          <Text
            textAlign="center"
            fontFamily="Inter"
            fontSize="14px"
            lineHeight="20px"
            color="gray.900"
          >
            Howdy is a Chrome extension, and you will need to switch to the desktop to
            start sending messages
          </Text>
        </Flex>
      </Box>
    );
  };

  return (
    <Grid h="100%" gridTemplateRows="min-content 1fr">
      <GridItem>
        <TopNavbar
          activeRoute={activeRoute}
          showTrialInfo={showTrialInfo}
          middleContent={navbarMiddleContent}
        />
      </GridItem>
      <GridItem>{renderChildren()}</GridItem>
    </Grid>
  );
};

export default InnerAppLayout;
