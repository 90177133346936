"use strict";
exports.__esModule = true;
exports.isTrackAnalyticsEventInput = exports.analyticsEvents = void 0;
var type_utils_1 = require("@workspace/type-utils");
exports.analyticsEvents = [
    'Campaign Created',
    'Campaign Cancelled',
    'Campaign Finished',
    'Campaign with Follow Up Created',
    'Message Template Edited',
    'Message Template Created',
    'Generated Magic DM',
    'List Upload',
    'Collected Targets',
    'Skip Previous Conversations Enabled',
    'Triggered Options Menu (/)',
    'Custom Message Created (Edited Template)',
    'Message Sent',
    'Created Integration',
    'Edited Integration',
    'Downgraded Subscription',
    'Upgraded Subscription',
    'Cancelled Subscription',
    'Started Free Trial',
    'Created Account',
    'DM Replied',
    'Campaign Incomplete Due to Credits'
];
var isAnalyticsEvent = (0, type_utils_1.isLiteralType)(exports.analyticsEvents);
exports.isTrackAnalyticsEventInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var event = _a.event;
    return isAnalyticsEvent(event);
});
