"use strict";
exports.__esModule = true;
exports.isCloudFunctionInput = void 0;
var CheckoutSession_1 = require("./CheckoutSession");
var instagramCampaigns_1 = require("./instagramCampaigns");
var InstagramPendingMessages_1 = require("./InstagramPendingMessages");
var InstagramUser_1 = require("./InstagramUser");
var zapierWebhooks_1 = require("./zapierWebhooks");
var discovery_1 = require("./discovery");
var FollowUpMessage_1 = require("./FollowUpMessage");
var logs_1 = require("./logs");
var MagicDM_1 = require("./MagicDM");
var blockedTargets_1 = require("./blockedTargets");
var TestingExtension_1 = require("./TestingExtension");
var Monitoring_1 = require("./Monitoring");
var Analytics_1 = require("./Analytics");
var List_1 = require("./List");
var CreditsCheckoutSession_1 = require("./CreditsCheckoutSession");
/**
 * Type-guards for cloud-function inputs.
 */
exports.isCloudFunctionInput = {
    createCheckoutSession: CheckoutSession_1.isCreateCheckoutSessionInput,
    createInstagramPendingMessage: InstagramPendingMessages_1.isCreateInstagramPendingMessageInput,
    readInstagramPendingMessages: InstagramPendingMessages_1.isReadInstagramPendingMessagesInput,
    resolveInstagramPendingMessages: InstagramPendingMessages_1.isResolveInstagramPendingMessageInput,
    markMessagesAsIgnored: InstagramPendingMessages_1.isMarkMessageAsIgnoredInput,
    writeInstagramUser: InstagramUser_1.isWriteInstagramUserInput,
    readInstagramCampaigns: instagramCampaigns_1.isReadInstagramCampaignsInput,
    updateInstagramCampaignStatus: instagramCampaigns_1.isUpdateInstagramCampaignsStatusesInput,
    nextInstagramMessage: instagramCampaigns_1.isNextInstagramMessageInput,
    increaseInstagramSentMessagesCount: instagramCampaigns_1.isIncreaseSentMessagesInput,
    skipCampaignMessage: instagramCampaigns_1.isSkipCampaignMessageInput,
    createInstagramCampaign: instagramCampaigns_1.isCreateInstagramCampaignInput,
    writeZapierWebhook: zapierWebhooks_1.isWriteZapierWebhookInput,
    deleteZapierWebhook: zapierWebhooks_1.isDeleteZapierWebhookInput,
    sendMessageResponsesToZapier: zapierWebhooks_1.isSendMessageResponsesToZapierInput,
    createDiscovery: discovery_1.isCreateDiscoveryInput,
    saveDiscoveryPosts: discovery_1.isSaveDiscoveryPostsInput,
    saveDiscoveryTargets: discovery_1.isSaveDiscoveryTargetsInput,
    saveActiveDiscoveryTab: discovery_1.isSaveActiveDiscoveryTabInput,
    createCampaignFromDiscovery: discovery_1.isCreateCampaignFromDiscoveryInput,
    describeInterestFromOpenAI: discovery_1.isDescribeInterestFromOpenAIInput,
    scrapeInstagramUser: InstagramUser_1.isScrapeInstagramUserInput,
    nextFollowUpMessage: FollowUpMessage_1.isNextFollowUpMessageInput,
    updateFollowUpMessageStatus: FollowUpMessage_1.isUpdateFollowUpMessageStatusInput,
    saveUserLogs: logs_1.isSaveUserLogsInput,
    getUserLogsByEmail: logs_1.isGetUserLogsByEmailInput,
    notifySlackAboutCampaignErrors: instagramCampaigns_1.isNotifySlackAboutCampaignErrorsInput,
    updateCampaignData: instagramCampaigns_1.isUpdateCampaignDataInput,
    triggerCampaignsCoolDown: instagramCampaigns_1.isTriggerCampaignsCoolDownInput,
    generateMagicDM: MagicDM_1.isGenerateMagicDMInput,
    getInstagramUserById: InstagramUser_1.isGetInstagramUserByIDInput,
    blockTarget: blockedTargets_1.isBlockTargetInput,
    uploadInstagramUserAvatar: InstagramUser_1.isUploadInstagramAvatarInput,
    updateDiscoverySession: discovery_1.isUpdateDiscoverySessionInput,
    saveDiscoveryReport: discovery_1.isSaveDiscoveryReportInput,
    generateTestingTargets: TestingExtension_1.isGenerateTestingTargetsInput,
    generateTestingMessages: TestingExtension_1.isGenerateTestingMessagesInput,
    saveMonitoringLog: Monitoring_1.isMonitorResponses,
    trackAnalyticEventFrontend: Analytics_1.isTrackAnalyticsEventInput,
    createList: List_1.isCreateListInput,
    addLeadsToList: List_1.isAddLeadsToListInput,
    removeLeadsFromList: List_1.isRemovedLeadsFromListInput,
    fetchUserLeads: List_1.isFetchUserLeadsSlug,
    fetchUserListBySlug: List_1.isFetchUserListBySlugInput,
    createCampaignFromLeads: List_1.isCreateCampaignFromLeadsInput,
    saveUnprocessedLeads: List_1.isSaveUnprocessedLeadsInput,
    getLeadsCampaign: List_1.isGetLeadsCampaignInput,
    deleteDiscoveryTarget: discovery_1.isDeleteDiscoveryTargetInput,
    createCreditsCheckoutSession: CreditsCheckoutSession_1.isCreditsCheckoutSessionInput
};
