import {Box, Flex, Text} from "@chakra-ui/react";
import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from "react";
import InnerAppLayout from "src/components/inner-app-layout";
import {DISCOVERY_CUSTOMER_DESCRIPTIONS, DISCOVERY_STEPS,} from "src/modules/Discovery";
import {
  AudienceStep,
  BusinessTypeStep,
  ExtraStep,
  HashtagsStep,
  ICPStep,
  InterestsStep,
  LocationStep,
  MINIMUM_ICP_PROFILE_URLS,
  MINIMUM_TOP_USERS_PROFILE_URLS,
  PricingStep,
  ProspectsStep,
  WelcomeStep
} from "src/modules/Discovery/components";
import {Button} from "@workspace/ui";
import {
  isArrayOfStrings,
  isAudienceLiteral,
  isBusinessType,
  isCloudFunctionInput,
  isPricingLiteral,
  isSellingLiteral,
} from "@workspace/firebase-datamodel";
import {firebaseAuth, firebaseFunctions} from "@workspace/firebase-app";
import {cloudFunctionName} from "@workspace/firebase-definitions";
import {isUniqueOrFirstOccurringProfileUrl, PROFILE_URL_REGEX,} from "src/modules/Discovery/components/consts";
import {getWelcomeEightFromLocalStorage, setWelcomeEightToLocalStorage,} from "./consts";
import {BUSINESS_TYPE, BusinessType, Discovery, instagramUserProfileUrl, TIME} from "@workspace/models";
import {isNonEmptyString, isNonNegativeInteger} from "@workspace/type-utils";
import {useDebounce} from "@workspace/react";
import {InstagramSession} from "../../modules/Discovery/components/InstagramSession";
import {SocialNetworkUser} from "@workspace/firebase-functions/@workspace/models";
import {useDsmChromeExtension} from "../../hooks";

const DEBOUNCE_TIME = TIME.ONE_SECOND;

const isValidProfileUrls = (
  profileUrls: unknown,
  min: number = 0,
): profileUrls is string[] => {
  if (!Array.isArray(profileUrls)) return false;

  const validProfileUrls = profileUrls.filter((profileUrl) =>
    PROFILE_URL_REGEX.test(profileUrl),
  );
  return (
    validProfileUrls.length >= min &&
    validProfileUrls.every((profileUrl, index) =>
      isUniqueOrFirstOccurringProfileUrl(validProfileUrls, profileUrl, index),
    )
  );
};

const getValueFromLocalStorage = <K extends keyof Discovery | (string & {}), T>(
  key: K,
  defaultValue: T,
  typeGuard: (arg: unknown) => arg is T,
  obj: Record<string, unknown> | null,
): T => {
  const cachedValue = obj?.[key];

  return typeGuard(cachedValue) ? cachedValue : defaultValue;
};

const defaultValues = {
  activeStep: 0,
  selling: null,
  otherService: "",
  customerDescription: "",
  customerInterests: "",
  extraPreferences: "",
  icpProfileUrls: Array.from({length: 1}, () => ""),
  audience: null,
  selectedCities: [],
  pricing: null,
  hashtags: [],
  companyName: "",
  website: "",
  linkToOffer: "",
  businessType: BUSINESS_TYPE[0] as BusinessType,
  topInstagramProfiles: [],
  icpProfiles: [],
}

type FormValue = keyof typeof defaultValues

const getInitialEight = (
  {
    localStorageDiscoveryRef
  }: {
    localStorageDiscoveryRef: React.MutableRefObject<Record<string, unknown> | null>;
  }
) => {
  const obj = localStorageDiscoveryRef.current;

  if(!obj) return defaultValues;

  return {
    selling: isSellingLiteral(obj.selling) ? obj.selling : null,
    otherService: isNonEmptyString(obj.otherService) ? obj.otherService : defaultValues.otherService,
    customerDescription: isNonEmptyString(obj.customerDescription) ? obj.customerDescription : defaultValues.customerDescription,
    extraPreferences: isNonEmptyString(obj.extraPreferences) ? obj.extraPreferences : defaultValues.extraPreferences,
    icpProfileUrls: isValidProfileUrls(obj.icpProfileUrls, MINIMUM_ICP_PROFILE_URLS) ? obj.icpProfileUrls : defaultValues.icpProfileUrls,
    audience: isAudienceLiteral(obj.audience) ? obj.audience : null,
    selectedCities: Array.isArray(obj.selectedCities) ? obj.selectedCities : [],
    topInstagramProfiles: Array.isArray(obj.topInstagramProfiles) ? obj.topInstagramProfiles : [],
    icpProfiles: Array.isArray(obj.icpProfiles) ? obj.icpProfiles : [],
    pricing: isPricingLiteral(obj.pricing) ? obj.pricing : null,
    hashtags: isArrayOfStrings(obj.hashtags) ? obj.hashtags : [],
    customerInterests: isNonEmptyString(obj.customerInterests) ? obj.customerInterests : defaultValues.customerInterests,
    companyName: isNonEmptyString(obj.companyName) ? obj.companyName : defaultValues.companyName,
    website: isNonEmptyString(obj.website) ? obj.website : defaultValues.website,
    linkToOffer: isNonEmptyString(obj.linkToOffer) ? obj.linkToOffer : defaultValues.linkToOffer,
    businessType: isBusinessType(obj.businessType) ? obj.businessType : defaultValues.businessType,
  }
}


export const WelcomeSteps: FC<{ onComplete: () => void }> = ({onComplete}) => {
  const localStorageDiscoveryRef = useRef(getWelcomeEightFromLocalStorage());
  const shouldDoAIPromptRequestRef = useRef<boolean>(false);

  const [{
    selling,
    otherService,
    customerDescription,
    customerInterests,
    extraPreferences,
    icpProfileUrls,
    audience,
    selectedCities,
    pricing,
    hashtags,
    businessType,
    companyName,
    website,
    linkToOffer,
    topInstagramProfiles,
    icpProfiles
  }, setDiscoveryValues] = useState(getInitialEight({localStorageDiscoveryRef}))

  const setSingleDiscoveryValue = (key: FormValue) => (value: any) => {
    setDiscoveryValues(prev => ({
      ...prev,
      [key]: value
    }))
  }


  const [activeStep, setActiveStep] = useState(() =>
    getValueFromLocalStorage(
      "activeStep",
      0,
      isNonNegativeInteger,
      localStorageDiscoveryRef.current,
    ),
  );

  const [instagramUserData, setInstagramUserData] = React.useState<(SocialNetworkUser & {
    session: string
  }) | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const debouncedCustomerDescription = useDebounce(customerDescription, DEBOUNCE_TIME);
  const debouncedExtraPreferences = useDebounce(extraPreferences, DEBOUNCE_TIME);
  const debouncedCustomerInterests = useDebounce(customerInterests, DEBOUNCE_TIME);

  const isOnInstagram = activeStep === DISCOVERY_STEPS.instagramSession;

  const isStepCompleted = useMemo(() => {
    switch (activeStep) {
      case DISCOVERY_STEPS.selling:
        if(selling === "Other"){
          return !!selling && !!otherService;
        }

        return !!selling;
      case DISCOVERY_STEPS.businessType:
        if(businessType === "Company"){
          return !!businessType && !!companyName && !!website;
        }

        return !!businessType;
      case DISCOVERY_STEPS.audience:
        return !!audience && !!customerDescription;
      case DISCOVERY_STEPS.location:
        return selectedCities.length > 0;
      case DISCOVERY_STEPS.interests:
        return !!customerInterests;
      case DISCOVERY_STEPS.topUsers:
        return topInstagramProfiles.length >= MINIMUM_TOP_USERS_PROFILE_URLS;
      case DISCOVERY_STEPS.hashtags:
        return hashtags.length > 0;
      case DISCOVERY_STEPS.icp:
        return true;
      case DISCOVERY_STEPS.pricing:
        return !!pricing;
      case DISCOVERY_STEPS.extraPreferences:
        return true;
      case DISCOVERY_STEPS.instagramSession:
        return !!instagramUserData;
    }
  }, [
    instagramUserData,
    activeStep,
    selling,
    audience,
    customerDescription,
    selectedCities,
    customerInterests,
    topInstagramProfiles,
    hashtags,
    pricing,
    otherService,
    businessType,
    companyName,
    website,
  ]);

  const handleAiDependantFieldChange = useCallback(
    <T, >(setter: (newValue: T) => void) =>
      (newValue: T) => {
        setter(newValue);
        shouldDoAIPromptRequestRef.current = true;
      },
    [],
  );

  const renderedStep = useMemo(() => {
    switch (activeStep) {
      case DISCOVERY_STEPS.selling:
        return (
          <WelcomeStep
            selectedSelling={selling}
            onChange={handleAiDependantFieldChange(setSingleDiscoveryValue("selling"))}
            otherService={otherService}
            onChangeOtherService={setSingleDiscoveryValue("otherService")}
          />
        );

        case DISCOVERY_STEPS.businessType:
        return (
          <BusinessTypeStep
            businessType={businessType}
            companyName={companyName}
            website={website}
            linkToOffer={linkToOffer}
            onChange={(key, value) => setSingleDiscoveryValue(key as FormValue)(value)}
          />
        );
      case DISCOVERY_STEPS.audience:
        return (
          <AudienceStep
            selectedAudience={audience}
            customerDescription={customerDescription}
            onCustomerDescriptionChange={setSingleDiscoveryValue("customerDescription")}
            onAudienceChange={handleAiDependantFieldChange(setSingleDiscoveryValue("audience"))}
          />
        );
      case DISCOVERY_STEPS.location:
        return (
          <LocationStep selectedCities={selectedCities} onChange={setSingleDiscoveryValue("selectedCities")}/>
        );
      case DISCOVERY_STEPS.interests:
        return (
          <InterestsStep
            customerInterests={customerInterests}
            onChange={setSingleDiscoveryValue("customerInterests")}
          />
        );
      case DISCOVERY_STEPS.topUsers:
        return (
          <ProspectsStep
            onChange={setSingleDiscoveryValue("topInstagramProfiles")}
            profiles={topInstagramProfiles}
          />
        );
      case DISCOVERY_STEPS.hashtags:
        return (
          <HashtagsStep
            selectedHashtags={hashtags}
            onChange={setSingleDiscoveryValue("hashtags")}
          />
        );
      case DISCOVERY_STEPS.icp:
        return <ICPStep profiles={icpProfiles} onChange={setSingleDiscoveryValue("icpProfiles")}/>;
      case DISCOVERY_STEPS.pricing:
        return <PricingStep selectedPricing={pricing} onChange={setSingleDiscoveryValue("pricing")}/>;
      case DISCOVERY_STEPS.extraPreferences:
        return (
          <ExtraStep extraPreferences={extraPreferences} onChange={setSingleDiscoveryValue("extraPreferences")}/>
        );
      case DISCOVERY_STEPS.instagramSession:
        return (
          <InstagramSession userData={instagramUserData} setUserData={setInstagramUserData}/>
        );
      default:
        return null;
    }
  }, [
    icpProfiles,
    instagramUserData,
    activeStep,
    selling,
    audience,
    customerDescription,
    selectedCities,
    customerInterests,
    extraPreferences,
    pricing,
    otherService,
    hashtags,
    handleAiDependantFieldChange,
    businessType,
    companyName,
    website,
    linkToOffer,
    topInstagramProfiles
  ]);

  const handleGetAIDescription = useCallback(async () => {
    if (!shouldDoAIPromptRequestRef.current) return;

    shouldDoAIPromptRequestRef.current = false;
    setIsLoading(true);

    try {
      const input = {audience, selling};

      if (!isCloudFunctionInput.describeInterestFromOpenAI(input)) return;

      const response = await firebaseFunctions.httpsCallable(
        cloudFunctionName.describeInterestFromOpenAI,
      )(input);

      if (typeof response.data !== "string") return;

      setSingleDiscoveryValue("customerInterests")(response.data);
    } finally {
      setIsLoading(false);
    }
  }, [audience, selling]);

  const handlePrevClick = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const {extensionSignIn} = useDsmChromeExtension();

  const handleComplete = useCallback(async () => {
    try {
      setIsLoading(true);

      const createDiscoveryInput = {
        selling,
        descriptionOfMainClients: customerDescription,
        clientInterests: customerInterests,
        clientLocation: selectedCities.map((cityOption) => cityOption.value),
        ICPExamples: icpProfileUrls.filter((profileUrl) => profileUrl),
        audience,
        topInstagramPages: topInstagramProfiles.map(profile => instagramUserProfileUrl(profile.username)),
        pricing,
        instagramSession: instagramUserData?.session
      };

      console.log(isCloudFunctionInput.createDiscovery(createDiscoveryInput), createDiscoveryInput)
      if (!isCloudFunctionInput.createDiscovery(createDiscoveryInput)) return;

      // Explain: This helps trigger the login on the background script and ensure it has the latest token
      const token = await firebaseAuth.currentUser?.getIdToken(true);

      extensionSignIn({
        token: token || "",
        uid: firebaseAuth.currentUser?.uid || "",
      });

      await firebaseFunctions.httpsCallable(cloudFunctionName.createDiscovery)(
        createDiscoveryInput,
      );
      await onComplete();
    } finally {
      setIsLoading(false);
    }
  }, [selling, customerDescription, topInstagramProfiles, customerInterests, selectedCities, icpProfileUrls, audience, pricing, instagramUserData?.session, extensionSignIn, onComplete]);

  const handleNextClick = useCallback(async () => {
    if (activeStep === DISCOVERY_STEPS.instagramSession) {
      try {
        handleComplete();
      } catch (e) {
        // @ts-ignore
        alert(e)
      }

      return;
    }
    if (activeStep === DISCOVERY_STEPS.location) {
      await handleGetAIDescription();
    }

    setActiveStep(activeStep + 1);
  }, [activeStep, handleGetAIDescription, handleComplete]);

  const actionButtons = useMemo(() => {
    return (
      <>
        <Button
          width="fit-content"
          color="gray.700"
          py="3"
          px="7"
          onClick={handlePrevClick}
          disabled={isLoading || activeStep === 0}
          variant="bordered"
          borderRadius="99px"
          mr="auto"
        >
          Back
        </Button>
        {activeStep === DISCOVERY_STEPS.icp && (
          <Button
            borderRadius="99px"
            color="gray.700"
            py="3"
            px="7"
            onClick={handleNextClick}
            disabled={isLoading}
            variant="bordered"
            mr="4"
          >
            I don’t have an ICP
          </Button>
        )}
        {activeStep === DISCOVERY_STEPS.location && (
          <Button
            borderRadius="99px"
            color="gray.700"
            py="3"
            px="7"
            onClick={handleNextClick}
            disabled={isLoading}
            variant="bordered"
            mr="4"
          >
            Skip
          </Button>
        )}
        {
          (activeStep !== DISCOVERY_STEPS.instagramSession) && (
            <Button
              width="fit-content"
              py="3"
              px="8"
              onClick={handleNextClick}
              isLoading={isLoading}
              disabled={!isStepCompleted || isLoading}
              borderRadius="99px"
            >
              Continue
            </Button>
          )
        }
      </>
    );
  }, [handleNextClick, handlePrevClick, isStepCompleted, isLoading, activeStep]);

  useEffect(() => {
    if (!audience || !selling) return;

    setSingleDiscoveryValue("customerDescription")(DISCOVERY_CUSTOMER_DESCRIPTIONS[selling][audience]);
  }, [audience, selling]);

  useEffect(() => {
    setWelcomeEightToLocalStorage({
      activeStep,
      customerDescription: debouncedCustomerDescription,
      customerInterests: debouncedCustomerInterests,
      extraPreferences: debouncedExtraPreferences,
      topInstagramProfiles,
      icpProfileUrls,
      audience,
      selectedCities,
      selling,
      pricing,
      hashtags,
    });
  }, [
    activeStep,
    debouncedCustomerDescription,
    debouncedCustomerInterests,
    debouncedExtraPreferences,
    topInstagramProfiles,
    icpProfileUrls,
    audience,
    selectedCities,
    selling,
    pricing,
    hashtags,
  ]);

  const renderStepText = () => {
    if(activeStep < DISCOVERY_STEPS.audience){
      return "Step 1 of 3"
    }else if(activeStep < DISCOVERY_STEPS.instagramSession){
      return "Step 2 of 3"
    }else{
      return "Step 3 of 3"
    }
  }


  const hasCompletedInstagramStep = activeStep === DISCOVERY_STEPS.instagramSession && isStepCompleted;

  return (
    <InnerAppLayout showTrialInfo>
      <Flex direction="column" height="100%">
        <Flex minHeight="90%" fontFamily="Inter">
          <Box
            px={{
              base: "48px",
              md: "64px",
              lg: "96px",
              xl: "142px",
            }}
            pt="104px"
            w="80%"
            maxWidth="800px"
            mx="auto"
          >
            <Text color="gray.900" opacity={0.4} mb="1" fontSize="14px">
              {renderStepText()}
            </Text>
            <Box mb={10}>{renderedStep}</Box>
            {
              hasCompletedInstagramStep && (
                <Button size="lg" variant="primary" borderRadius={99} onClick={handleComplete} isLoading={isLoading} disabled={isLoading}>
                  Complete setup
                </Button>
              )
            }
          </Box>
        </Flex>
        {
          !hasCompletedInstagramStep && (
            <Flex px="4" py="3.5" borderTop="1px solid rgba(11, 13, 14, 0.10)" mt="auto">
              {actionButtons}
            </Flex>
          )
        }
      </Flex>
    </InnerAppLayout>
  );
};
