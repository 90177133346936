import React from "react";
import {chakra, Flex, Spinner, Text} from "@chakra-ui/react";
import {Icon} from "@workspace/ui";
import {PRICE_PER_CREDITS, getCreditsTotalPrice} from "@workspace/models";
import {useMutation} from "@tanstack/react-query";
import {firebaseFunctions} from "@workspace/firebase-app";
import {cloudFunctionName} from "@workspace/firebase-definitions";
import {isCloudFunctionOutput} from "@workspace/firebase-functions/@workspace/firebase-datamodel";

const Card = chakra(Flex, {
  baseStyle: {
    padding: "16px 24px",
    border: "1px solid #0B0D0E1A",
    boxShadow: "0px 5px 12px 0px #0000000D",
    width: '100%',
    alignItems: 'center',
    borderRadius: '8px',
    cursor: 'pointer'
  },
});

const CreditCard = ({ credits }: { credits: number }) => {
  const actualPrice = getCreditsTotalPrice(credits);
  const normalPrice = credits * PRICE_PER_CREDITS;

  const creditsMutation = useMutation({
    mutationFn: async () => {
      const response = await firebaseFunctions.httpsCallable(cloudFunctionName.createCreditsCheckoutSession)({
        credits
      });

      if(isCloudFunctionOutput.createCreditsCheckoutSession(response.data)) {
        return response.data;
      }

      throw new Error('Invalid response')
    },
    onSuccess: ({ url }) => {
      window.location.href = url
    },
    onError: error => {
      console.error(error)
      alert('Something went wrong')
    }
  })

  const renderCredits = () => {
    if(normalPrice === actualPrice){
      return (
        <Text fontSize={14} color='gray.900'>
          ${normalPrice}
        </Text>
      )
    }else{
      return (
        <>
          <Text fontSize={14} mr={3} color='gray.900' style={{textDecoration: 'line-through', opacity: 0.3}}>
            ${normalPrice}
          </Text>
          <Text fontSize={14} color='gray.900'>
            ${actualPrice}
          </Text>
        </>
      )
    }
  }

  return (
    <Card onClick={creditsMutation.mutate} opacity={creditsMutation.isPending ? 0.5 : 1}>
      <Spinner size='xs' hidden={!creditsMutation.isPending} mr={2}/>
      <Icon color='#FF9900' name='credits' />
      <Text fontSize={14} fontWeight={600} ml={2} mr='auto'>
        {credits} credits
      </Text>
      {renderCredits()}
    </Card>
  );
};

export default CreditCard;
