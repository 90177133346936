import { useQuery } from "@tanstack/react-query";
import { firebaseFirestore, firebaseFunctions as functions } from "@workspace/firebase-app";
import { captureException } from "@sentry/browser";
import { cloudFunctionName } from "@workspace/firebase-definitions";
import { collectionName, isCloudFunctionOutput, isUser } from "@workspace/firebase-datamodel";
import { useEffect, useState } from "react";
export const useUserCredits = (userId) => {
    const [credits, setCredits] = useState();
    const query = useQuery({
        queryKey: ['userCredits', userId],
        queryFn: async () => {
            try {
                const { data } = await functions.httpsCallable(cloudFunctionName.fetchUserCredits)();
                if (isCloudFunctionOutput.fetchUserCredits(data)) {
                    setCredits(data.credits);
                    return data.credits;
                }
            }
            catch (error) {
                captureException(error);
            }
            return 0;
        }
    });
    useEffect(() => {
        let unsub;
        if (userId) {
            unsub = firebaseFirestore
                .collection(collectionName.users)
                .doc(userId)
                .onSnapshot(async (snapshot) => {
                const snapshotData = snapshot.data();
                if (isUser(snapshotData)) {
                    setCredits(snapshotData.credits);
                }
            });
        }
        return () => {
            unsub?.();
        };
    }, [userId, query]);
    return {
        ...query,
        data: credits
    };
};
