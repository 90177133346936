
export const PRICE_PER_CREDITS = 0.1;

export const getCreditsDiscount = (credits: number) => {
  if(credits < 100) {
    return 0;
  } else if(credits >= 100 && credits < 500) {
    return 10;
  } else if(credits >= 500 && credits < 1000) {
    return 15;
  }else if(credits >= 1000 && credits < 2000) {
    return 20;
  }else{
    return 30
  }
}

export const getCreditsTotalPrice = (credits: number) => {
  const totalPrice = credits * 0.1;
  const discount = getCreditsDiscount(credits);
  const discountedAmount = totalPrice * discount / 100

  return Number(Number(totalPrice - discountedAmount).toFixed(2));
};
