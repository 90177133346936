import {isCreateCheckoutSessionInput} from "./CheckoutSession";
import {
  isCreateInstagramCampaignInput,
  isIncreaseSentMessagesInput,
  isNextInstagramMessageInput,
  isNotifySlackAboutCampaignErrorsInput,
  isReadInstagramCampaignsInput,
  isSkipCampaignMessageInput,
  isTriggerCampaignsCoolDownInput,
  isUpdateCampaignDataInput,
  isUpdateInstagramCampaignsStatusesInput,
} from "./instagramCampaigns";
import {
  isCreateInstagramPendingMessageInput,
  isMarkMessageAsIgnoredInput,
  isReadInstagramPendingMessagesInput,
  isResolveInstagramPendingMessageInput,
} from "./InstagramPendingMessages";
import {
  isGetInstagramUserByIDInput,
  isScrapeInstagramUserInput,
  isUploadInstagramAvatarInput,
  isWriteInstagramUserInput,
} from "./InstagramUser";
import {
  isDeleteZapierWebhookInput,
  isSendMessageResponsesToZapierInput,
  isWriteZapierWebhookInput,
} from "./zapierWebhooks";
import {
  isCreateCampaignFromDiscoveryInput,
  isCreateDiscoveryInput, isDeleteDiscoveryTargetInput,
  isDescribeInterestFromOpenAIInput,
  isSaveActiveDiscoveryTabInput,
  isSaveDiscoveryPostsInput,
  isSaveDiscoveryReportInput,
  isSaveDiscoveryTargetsInput,
  isUpdateDiscoverySessionInput,
} from "./discovery";
import {isNextFollowUpMessageInput, isUpdateFollowUpMessageStatusInput,} from "./FollowUpMessage";
import {isGetUserLogsByEmailInput, isSaveUserLogsInput} from "./logs";
import {isGenerateMagicDMInput} from "./MagicDM";
import {isBlockTargetInput} from "./blockedTargets";
import {isGenerateTestingMessagesInput, isGenerateTestingTargetsInput,} from "./TestingExtension";
import {isMonitorResponses} from "./Monitoring";
import {isTrackAnalyticsEventInput} from "./Analytics";
import {
  isAddLeadsToListInput,
  isCreateCampaignFromLeadsInput,
  isCreateListInput,
  isFetchUserLeadsSlug,
  isFetchUserListBySlugInput,
  isGetLeadsCampaignInput,
  isRemovedLeadsFromListInput,
  isSaveUnprocessedLeadsInput
} from "./List";
import {isCreditsCheckoutSessionInput} from "./CreditsCheckoutSession";

/**
 * Type-guards for cloud-function inputs.
 */
export const isCloudFunctionInput = {
  createCheckoutSession: isCreateCheckoutSessionInput,
  createInstagramPendingMessage: isCreateInstagramPendingMessageInput,
  readInstagramPendingMessages: isReadInstagramPendingMessagesInput,
  resolveInstagramPendingMessages: isResolveInstagramPendingMessageInput,
  markMessagesAsIgnored: isMarkMessageAsIgnoredInput,
  writeInstagramUser: isWriteInstagramUserInput,
  readInstagramCampaigns: isReadInstagramCampaignsInput,
  updateInstagramCampaignStatus: isUpdateInstagramCampaignsStatusesInput,
  nextInstagramMessage: isNextInstagramMessageInput,
  increaseInstagramSentMessagesCount: isIncreaseSentMessagesInput,
  skipCampaignMessage: isSkipCampaignMessageInput,
  createInstagramCampaign: isCreateInstagramCampaignInput,
  writeZapierWebhook: isWriteZapierWebhookInput,
  deleteZapierWebhook: isDeleteZapierWebhookInput,
  sendMessageResponsesToZapier: isSendMessageResponsesToZapierInput,
  createDiscovery: isCreateDiscoveryInput,
  saveDiscoveryPosts: isSaveDiscoveryPostsInput,
  saveDiscoveryTargets: isSaveDiscoveryTargetsInput,
  saveActiveDiscoveryTab: isSaveActiveDiscoveryTabInput,
  createCampaignFromDiscovery: isCreateCampaignFromDiscoveryInput,
  describeInterestFromOpenAI: isDescribeInterestFromOpenAIInput,
  scrapeInstagramUser: isScrapeInstagramUserInput,
  nextFollowUpMessage: isNextFollowUpMessageInput,
  updateFollowUpMessageStatus: isUpdateFollowUpMessageStatusInput,
  saveUserLogs: isSaveUserLogsInput,
  getUserLogsByEmail: isGetUserLogsByEmailInput,
  notifySlackAboutCampaignErrors: isNotifySlackAboutCampaignErrorsInput,
  updateCampaignData: isUpdateCampaignDataInput,
  triggerCampaignsCoolDown: isTriggerCampaignsCoolDownInput,
  generateMagicDM: isGenerateMagicDMInput,
  getInstagramUserById: isGetInstagramUserByIDInput,
  blockTarget: isBlockTargetInput,
  uploadInstagramUserAvatar: isUploadInstagramAvatarInput,
  updateDiscoverySession: isUpdateDiscoverySessionInput,
  saveDiscoveryReport: isSaveDiscoveryReportInput,
  generateTestingTargets: isGenerateTestingTargetsInput,
  generateTestingMessages: isGenerateTestingMessagesInput,
  saveMonitoringLog: isMonitorResponses,
  trackAnalyticEventFrontend: isTrackAnalyticsEventInput,
  createList: isCreateListInput,
  addLeadsToList: isAddLeadsToListInput,
  removeLeadsFromList: isRemovedLeadsFromListInput,
  fetchUserLeads: isFetchUserLeadsSlug,
  fetchUserListBySlug: isFetchUserListBySlugInput,
  createCampaignFromLeads: isCreateCampaignFromLeadsInput,
  saveUnprocessedLeads: isSaveUnprocessedLeadsInput,
  getLeadsCampaign: isGetLeadsCampaignInput,
  deleteDiscoveryTarget: isDeleteDiscoveryTargetInput,
  createCreditsCheckoutSession: isCreditsCheckoutSessionInput,
};
