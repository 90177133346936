interface RouteData {
  route: string;
  title?: string;
}

type ExtendedRouteData = RouteData & {
  [key: string]: string;
};

const routes = {
  Analytics: {route: "/analytics"},
  Action: {route: "/action"},
  BuildingInboxWall: {route: "/building-inbox"},
  Campaigns: {route: "/campaigns", bulkSendRoute: "/campaigns/personalization"},
  ChromeExt: {
    route: "/chrome-extension",
  },
  Conversations: {
    route: "/conversations",
    composeNewRoute: "/conversations/new",
    blockedConversations: "/conversations/blocked",
    idSlug: "currentConversationId",
  },
  CreateAccount: {route: "/auth/register", title: "Create account"},
  Followers: {route: "/community"},
  ForgotPassword: {route: "/forgot-password", title: "Forgot password?"},
  GetStarted: {route: "/get-started"},
  Login: {route: "/auth/sign-in", title: "Log in"},
  Home: {route: "/"},
  Settings: {route: "/settings"},
  Integrations: {
    route: "/integrations",
    facebookIntegrationSuccess: "/integrations/facebook/success",
    facebookIntegrationFailure: "/integrations/facebook/failure",
    twitterIntegrationSuccess: "/integrations/twitter/success",
    twitterIntegrationFailure: "/integrations/twitter/failure",
    instagramIntegrationFailure: "/integrations/instagram/failure",
  },
  PaymentPlans: {
    route: "/plans",
    paymentSuccess: "/plans/success",
    paymentFailure: "/plans/failure",
  },
  Discovery: {
    route: "/discovery",
  },
  Lists: {
    route: "/lists",
    listDetails: "/lists/:slug"
  },
  Credits: {
    route: "/credits",
  }
} satisfies Record<string, ExtendedRouteData>;

export default routes;
