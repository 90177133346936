import {envIsProd} from "@workspace/env";
import {nakedDomain} from "./nakedDomain";

export const webappDomainNameProd = `app.${nakedDomain}`;
export const webappDomainNameDev = `app.dev.${nakedDomain}`;

export const webappDomainName = envIsProd ? webappDomainNameProd : webappDomainNameDev;

export const webappBaseUrl = `https://${webappDomainName}`;

export const webappPathname = {
  forgotPassword: () => "/forgot-password",
  getStarted: () => "/get-started",
  register: () => "/auth/register",
  upgradeSubscription: () => "/plans",
  stripeCancelUrl: () => "/payments/failure",
  stripeSuccessUrl: () => "/get-started",
  integrations: () => "/integrations",
  emailVerification: () => "/email-verification",
  settings: () => "/settings",
  discovery: () => "/discovery",
  lists: () => "/lists",
  credits: () => "/credits",
};

export const webappUrl = {
  forgotPassword: () => `${webappBaseUrl}${webappPathname.forgotPassword()}`,
  getStarted: () => `${webappBaseUrl}${webappPathname.getStarted()}`,
  register: () => `${webappBaseUrl}${webappPathname.register()}`,
  stripeCancelUrl: () => `${webappBaseUrl}${webappPathname.stripeCancelUrl()}`,
  stripeSuccessUrl: () => `${webappBaseUrl}${webappPathname.stripeSuccessUrl()}`,
  upgradeSubscription: () => `${webappBaseUrl}${webappPathname.upgradeSubscription()}`,
  integrations: () => `${webappBaseUrl}${webappPathname.integrations()}`,
  emailVerification: () => `${webappBaseUrl}${webappPathname.emailVerification()}`,
  settings: () => `${webappBaseUrl}${webappPathname.settings()}`,
  discovery: () => `${webappBaseUrl}${webappPathname.discovery()}`,
  lists: () => `${webappBaseUrl}${webappPathname.lists()}`,
  credits: () => `${webappBaseUrl}${webappPathname.credits()}`,
  // @ts-ignore explain: the compiler doesn't work with arguments for some reason so I had to make the arg typeless todo: fix the import of locators on the frontend
  listBySlug: (slug) => `${webappBaseUrl}${webappPathname.lists()}/${slug}`,
};

export const externalUrl = {
  featureRequest: () => `https://tally.so/r/mJd76J`,
};

export const userListCampaignQueryKey = "list-campaign";
