"use strict";
exports.__esModule = true;
exports.getCreditsTotalPrice = exports.getCreditsDiscount = exports.PRICE_PER_CREDITS = void 0;
exports.PRICE_PER_CREDITS = 0.1;
var getCreditsDiscount = function (credits) {
    if (credits < 100) {
        return 0;
    }
    else if (credits >= 100 && credits < 500) {
        return 10;
    }
    else if (credits >= 500 && credits < 1000) {
        return 15;
    }
    else if (credits >= 1000 && credits < 2000) {
        return 20;
    }
    else {
        return 30;
    }
};
exports.getCreditsDiscount = getCreditsDiscount;
var getCreditsTotalPrice = function (credits) {
    var totalPrice = credits * 0.1;
    var discount = (0, exports.getCreditsDiscount)(credits);
    var discountedAmount = totalPrice * discount / 100;
    return Number(Number(totalPrice - discountedAmount).toFixed(2));
};
exports.getCreditsTotalPrice = getCreditsTotalPrice;
