import {Center, ChakraProvider} from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import {
  DiscoveryDataProvider,
  FirebaseUserContextProvider,
  MessageTemplateStateProvider,
  RemoteConfigProvider,
} from "@workspace/react";
import {FC} from "react";
import {BrowserRouter} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

import AppRoutes from "./router/app-routes";
import Fonts from "./theme/fonts";
import theme from "./theme/theme";

import {MixpanelProvider, UserAuthProvider} from "src/contexts";

import "@yaireo/tagify/dist/tagify.css";
import "react-datepicker/dist/react-datepicker.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

export const App: FC = () => {
  return (
    <Sentry.ErrorBoundary
      fallback={() => (
        <Center fontSize="24px" pt="20vh" fontFamily="Arial">
          Something went wrong. Please try refreshing.
        </Center>
      )}
    >
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <Fonts />
          <MixpanelProvider>
            <UserAuthProvider>
              <FirebaseUserContextProvider>
                <DiscoveryDataProvider>
                  {/* @ts-ignore */}
                  <BrowserRouter>
                    <RemoteConfigProvider>
                      <MessageTemplateStateProvider>
                        <AppRoutes />
                      </MessageTemplateStateProvider>
                    </RemoteConfigProvider>
                  </BrowserRouter>
                </DiscoveryDataProvider>
              </FirebaseUserContextProvider>
            </UserAuthProvider>
          </MixpanelProvider>
        </ChakraProvider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
};
