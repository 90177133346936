import {Operation} from "@workspace/models";
import {
  isNonEmptyString,
  isNonNegativeInteger,
  NonEmptyString,
  objectTypeGuard,
} from "@workspace/type-utils";
import {CreateCheckoutSession} from "./CheckoutSession";

export type CreditsCheckoutSessionStripeMetadata = {
  userId: string;
  credits: string;
};

export type CreditsCheckoutSession = Operation<{credits: number}, {url: NonEmptyString}>

export const isCreditsCheckoutSessionInput = objectTypeGuard<CreditsCheckoutSession["in"]>(
  ({credits}) => isNonNegativeInteger(credits),
);

export const isCreditsCheckoutSessionOutput = objectTypeGuard<
  CreateCheckoutSession["out"]
>(({url}) => isNonEmptyString(url));
