import {Box} from "@chakra-ui/react";
import {Heading, Tab, TabList, Tabs} from "@workspace/ui";

import React, {useState} from "react";
import InnerAppLayout from "../../../components/inner-app-layout";
import {AccountDetails} from "../components/AccountDetails";
import {Subscription} from "../components/Subscription";
import ZapierWebhooks from "../components/ZapierWebhooks";

const SETTINGS_TABS = {
  account: {
    label: "Account",
    value: 0,
  },
  // discovery: {
  //   label: "Discovery",
  //   value: "discovery",
  // },
  credits: {
    label: "Credits",
    value: 1,
  },
  zapier: {
    label: "Zapier",
    value: 2,
  },
} as const;

const SETTINGS_TABS_ARRAY = Object.values(SETTINGS_TABS);

type SettingsTab = (typeof SETTINGS_TABS)[keyof typeof SETTINGS_TABS]["value"];

const Settings: React.FC = () => {
  const [activeTab, setActiveTab] = useState<SettingsTab>(SETTINGS_TABS.account.value);

  const renderActiveTab = () => {
    switch (activeTab) {
      case SETTINGS_TABS.account.value:
        return <AccountDetails />;
      case SETTINGS_TABS.credits.value:
        return <Subscription />;
      case SETTINGS_TABS.zapier.value:
        return <ZapierWebhooks />;
      default:
        return null;
    }
  };

  return (
    <InnerAppLayout showTrialInfo>
      <Box maxWidth="1080px" mx="auto" mt="12">
        <Heading variant="h1" mb="12">
          Settings
        </Heading>
        <Tabs variant="line" mb="56px" borderColor="alpha.10">
          <TabList gap="8">
            {SETTINGS_TABS_ARRAY.map((tab) => (
              <Tab px="0" key={tab.value} onClick={() => setActiveTab(tab.value)}>
                {tab.label}
              </Tab>
            ))}
          </TabList>
        </Tabs>
        <Box>{renderActiveTab()}</Box>
      </Box>
    </InnerAppLayout>
  );
};

export default Settings;
