import {Box, Divider, Flex, Text} from "@chakra-ui/react";
import {useFirebaseUser, useUserCredits} from "@workspace/react";
import {Button, Heading, Icon} from "@workspace/ui";
import {FC, useContext} from "react";
import {routes} from "src/router";
import {Link} from "react-router-dom";
import {UserAuthContext} from "../../../contexts";

function formatNumber(number = 0) {
  return new Intl.NumberFormat('en-US').format(number);
}

export const Subscription: FC = () => {
  const {userInfo} = useFirebaseUser();
  const userCredits = useUserCredits(userInfo?.uid)

  return (
    <Flex gap="4" fontFamily="Inter">
      <Box width="40%">
        <Heading as="h2" color="gray.900" variant="h3" mb="2">
          Your Howdy Credits
        </Heading>
        <Text color="gray.600" fontSize="14px" lineHeight="20px">
          View and buy new credits
        </Text>
      </Box>
      <Box
        p="8"
        width="60%"
        borderRadius="md"
        border="1px solid rgba(0, 0, 0, 0.08)"
        boxShadow="0px 5px 12px rgba(0, 0, 0, 0.05)"
      >
        <Flex
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Flex alignItems={"center"}>
            <Icon name="credits" color="#FF9900" />
            <Text ml={2} color="gray.900" fontWeight="bold" fontSize="md">
              {formatNumber(userCredits.data)} credits
            </Text>
          </Flex>
          <Link to={routes.Credits.route}>
            <Button
              variant="primary"
              px="4"
              py="3"
            >
              Buy Credits
            </Button>
          </Link>
        </Flex>
        <Divider my="6" />
        <Box>
          <Text color="gray.600" fontSize="base">
            Email:
          </Text>
          <Text color="gray.900" fontSize="base">
            {userInfo?.email ?? ""}
          </Text>
        </Box>
      </Box>
    </Flex>
  );
};
