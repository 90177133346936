import React, {ChangeEvent, useState} from "react";
import InnerAppLayout from "../../components/inner-app-layout";
import {Box, chakra, Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from "@chakra-ui/react";
import {Button, Icon, Input, Modal} from "@workspace/ui";
import {firebaseFunctions as functions, firebaseFunctions} from "@workspace/firebase-app";
import {cloudFunctionName} from "@workspace/firebase-definitions";
import {List} from "@workspace/models";
import {isCloudFunctionOutput} from "@workspace/firebase-functions/@workspace/firebase-datamodel";
import {GeneralLoading} from "../../components";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import {Link} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";


const StyledTh = chakra(Th, {
  baseStyle: {
    letterSpacing: "0px",
    textTransform: "capitalize",
    fontSize: "14px",
    fontWeight: 400,
    color: "#0B0D0E80"
  }
})

const StyledTr = chakra(Tr, {
  baseStyle: {
    cursor: "pointer",
    fontSize: "14px"
  },
})

const ViewLists = () => {
  const [newListName, setNewListName] = useState("");
  const [createModalIsVisible, setCreateModalIsVisible] = useState(false);

  const qc = useQueryClient()

  const listsQuery = useQuery(
    {
      queryKey: ["fetchUsersList"],
      queryFn: async () => {
        try {
          const {data} = await functions.httpsCallable(cloudFunctionName.fetchUserLists)();

          if (isCloudFunctionOutput.fetchUserLists(data)) {
            return data
          }

          throw new Error("Failed to fetch lists")
        } catch (e) {
          throw new Error("Failed to fetch lists")
        }
      },
    },
  )

  const createListMutation = useMutation(
    {
      mutationFn: async () => {
        const response = await firebaseFunctions.httpsCallable(
          cloudFunctionName.createList,
        )({
          name: newListName
        });

        return response.data
      },
      onSuccess: (data) => {
        if (isCloudFunctionOutput.createList(data)) {
          qc.setQueryData(["fetchUsersList"], (prev: List[]) => [data, ...prev])
        }

        setCreateModalIsVisible(false)
      }
    },
  )

  const toggleCreateModalIsVisible = () => {
    setCreateModalIsVisible(prev => !prev)
    setNewListName("")
  };

  const onChangeInput = ({target}: ChangeEvent<HTMLInputElement>) => {
    setNewListName(target.value);
  }

  const renderBody = () => {
    if (listsQuery.isFetching) {
      return (
        <GeneralLoading zIndex={2}/>
      )
    }

    const lists: List[] = listsQuery.data || [];


    if (listsQuery.isSuccess && lists.length === 0) {
      return (
        <Flex
          mt={20}
          mx="auto"
          width="528px"
          flexDirection="column"
          align="center"
          p={16}
          bg="white"
          boxShadow="0px 1px 3px 0px rgba(10, 9, 68, 0.13), 0px 3px 14px 0px rgba(10, 9, 68, 0.03), 0px 8px 32px 0px rgba(10, 9, 68, 0.07), 0px 30px 84px 0px rgba(10, 9, 68, 0.08)"
          borderRadius="16px"
        >
          <Text fontWeight={700} color="gray.900" fontFamily="Circular" mb={4} fontSize="24px" lineHeight="32px"
                textAlign="center">
            Create a list of your leads
          </Text>
          <Text fontWeight="14px" lineHeight="20px" color="gray.900" mb={8} textAlign="center">
            Reduce your manual workload by adding leads to your list from different sources like comments and likes, and
            apply filters for better targeting when sending messages.
          </Text>
          <Button variant="primary" width="200px" size="lg" onClick={toggleCreateModalIsVisible}>
            Create new list
          </Button>
        </Flex>
      )
    }

    return (
      <Box width="832px" mx="auto" pt="10" fontFamily="Inter">
        <Flex align="center" justify="space-between" mb="42">
          <Text fontSize="24px" fontWeight="600">
            Lists
          </Text>
          <Button variant="primary" onClick={toggleCreateModalIsVisible}>
            Create list
          </Button>
        </Flex>
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <StyledTh>Name</StyledTh>
                <StyledTh>Leads</StyledTh>
                <StyledTh>Created</StyledTh>
              </Tr>
            </Thead>
            <Tbody>
              {
                lists.map(list => (
                  <StyledTr key={list.id} as={Link} to={`/lists/${list.slug}`} display="table-row">
                    <Td fontWeight={500} color="gray.900">
                      <Flex align="center">
                        <Flex w={10} h={10} mr={4} align="center" justify="center" color="green.600" bg="green.50"
                              borderRadius={8}>
                          <Icon name="lists" size={20}/>
                        </Flex>
                        <Text>
                          {list.name}
                        </Text>
                      </Flex>
                    </Td>
                    <Td color="#0B0D0E80">
                      <Flex align="center" gap={1}>
                        <Icon name="users"/>
                        <Text>
                          {list.totalLeads}
                        </Text>
                      </Flex>
                    </Td>
                    <Td color="#0B0D0E80">
                      {formatDistanceToNow(new Date(list.whenCreated), {addSuffix: true})}
                    </Td>
                  </StyledTr>
                ))
              }
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    )
  }

  return (
    <InnerAppLayout>
      {renderBody()}
      <Modal
        title="Create New List"
        onClose={toggleCreateModalIsVisible}
        contentProps={{
          width: "408px",
        }}
        isOpen={createModalIsVisible}
        onConfirm={createListMutation.mutate}
        confirmButtonProps={{
          children: "Create list",
          variant: "primary",
          disabled: newListName.trim().length === 0,
          isLoading: createListMutation.isPending
        }}
        closeButtonProps={{
          children: "Cancel",
          variant: "bordered",
          disabled: createListMutation.isPending
        }}
        isCentered
      >
        <Input
          isInvalid={createListMutation.isError}
          value={newListName}
          onChange={onChangeInput}
        />
        {createListMutation.isError && <Text fontSize="12px" color="red.500" lineHeight="1.33" mt={2}>{createListMutation.error.message}</Text>}
      </Modal>
    </InnerAppLayout>
  );
};

export default ViewLists;
