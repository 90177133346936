import {Alert, Box, Flex, Text} from "@chakra-ui/react";
import {FC, useCallback, useEffect, useMemo, useState} from "react";
import {useLocation} from "react-router";

import {FIREBASE_FUNC_DOMAIN} from "src/env";
import {BlurredBackground, OutlinedBox, SocialIntegrationBox} from "src/components";
import InnerAppLayout from "../../../components/inner-app-layout";
import {getIdToken} from "../../auth/auth-service";
import Layout from "../components/layout";
import {colorHex, Heading} from "@workspace/ui";

export const IS_CONNECTING_TWITTER_LOCAL_STORAGE_KEY =
  "IS_CONNECTING_TWITTER_LOCAL_STORAGE_KEY";

enum ErrorCode {
  MissingToken = "missing_oauth_token",
}

const errorMessagesMap: Record<string, string> = {
  [ErrorCode.MissingToken]: "Missing oauth token",
};

export const Integrations: FC = () => {
  const [idToken, setIdToken] = useState<string>();
  const location = useLocation<{search: string}>();
  const params = new URLSearchParams(location.search);
  const errorCode = params.get("error");
  const errorMessage = errorCode ? errorMessagesMap[errorCode] : undefined;
  useEffect(() => {
    getIdToken()
      .then((token) => {
        if (token) {
          setIdToken(token);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const authTwitterHref = useMemo(
    () =>
      idToken
        ? `${FIREBASE_FUNC_DOMAIN}${encodeURI(
            "/twitterWebhooks/oauth/init",
          )}?userIdToken=${idToken}`
        : "",
    [idToken],
  );

  const authFacebookHref = useMemo(
    () =>
      idToken
        ? `${FIREBASE_FUNC_DOMAIN}${encodeURI(
            "/facebookWebhooks/oauth/init",
          )}?userIdToken=${idToken}`
        : "",
    [idToken],
  );

  const handleTwitterConnect = useCallback(() => {
    try {
      localStorage.setItem(IS_CONNECTING_TWITTER_LOCAL_STORAGE_KEY, "true");
    } catch {}
  }, []);

  return (
    <InnerAppLayout showTrialInfo>
      <Layout gridTemplateColumns="1fr minmax(auto, 564px) 1fr">
        {idToken ? (
          <Box position="relative" display="inline-block">
            <BlurredBackground colors={["#E1306C", "#1DA1F2"]} />
            <OutlinedBox m="8" bg="white" zIndex="2" position="relative">
              <Heading as="h1" variant="h3" color={colorHex.gray900} mb="4">
                Choose where to send messages
              </Heading>
              <Text mb={8} color={colorHex.gray900} fontSize="baseMinor">
                Select your social account and use Chrome Extension to send DMs to your
                network
              </Text>
              {typeof errorMessage !== "undefined" && (
                <Alert status="error" px={6} py={4} my={6}>
                  <Text textAlign="left">{errorMessage}</Text>
                </Alert>
              )}
              <Flex flexDirection="column" gap="4">
                <SocialIntegrationBox
                  connectionUrl={authFacebookHref}
                  socialNetwork="Instagram"
                />
                <SocialIntegrationBox
                  connectionUrl={authTwitterHref}
                  socialNetwork="Twitter"
                  onConnectClick={handleTwitterConnect}
                />
              </Flex>
            </OutlinedBox>
          </Box>
        ) : null}
      </Layout>
    </InnerAppLayout>
  );
};
