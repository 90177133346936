import {isCreateBillingPortalSessionOutput} from "./BillingPortalSession";
import {isCreateCheckoutSessionOutput} from "./CheckoutSession";
import {isReadConversationOutput} from "./Conversation";
import {
  isCreateInstagramCampaignOutput,
  isNextInstagramMessageOutput,
  isReadInstagramCampaignsOutput,
} from "./instagramCampaigns";
import {isReadMessagesDailyCountOutput} from "./MessagesDailyCount";
import {isReadMessagesDailyQuotaOutput} from "./MessagesDailyQuota";
import {isReadSubscriptionOutput} from "./Subscription";
import {isReadInstagramUserOutput, isScrapeInstagramUserOutput} from "./InstagramUser";
import {isReadInstagramPendingMessagesOutput} from "./InstagramPendingMessages";
import {isDeleteDiscoveryTargetOutput, isNextDiscoveryActionOutput} from "./discovery";
import {isNextFollowUpMessageOutput} from "./FollowUpMessage";
import {isGenerateMagicDMOutput} from "./MagicDM";
import {
  isCreateListOutput,
  isFetchUnprocessedLeadsOutput,
  isFetchUserLeadsOutput,
  isFetchUserListsOutput,
  isGetLeadsCampaignOutput
} from "./List";
import {isCreditsCheckoutSessionOutput} from "./CreditsCheckoutSession";
import {isFetchUserCreditsOutput} from "./Credits";

/**
 * Type-guards for cloud-function outputs.
 */
export const isCloudFunctionOutput = {
  createBillingPortalSession: isCreateBillingPortalSessionOutput,
  createCheckoutSession: isCreateCheckoutSessionOutput,
  createInstagramCampaign: isCreateInstagramCampaignOutput,
  readSubscription: isReadSubscriptionOutput,
  readConversation: isReadConversationOutput,
  readMessagesDailyCount: isReadMessagesDailyCountOutput,
  readMessagesDailyQuota: isReadMessagesDailyQuotaOutput,
  readInstagramUser: isReadInstagramUserOutput,
  readInstagramCampaigns: isReadInstagramCampaignsOutput,
  nextInstagramMessage: isNextInstagramMessageOutput,
  readInstagramPendingMessages: isReadInstagramPendingMessagesOutput,
  nextDiscovery: isNextDiscoveryActionOutput,
  scrapeInstagramUser: isScrapeInstagramUserOutput,
  nextFollowUpMessage: isNextFollowUpMessageOutput,
  generateMagicDM: isGenerateMagicDMOutput,
  fetchUserLists: isFetchUserListsOutput,
  createList: isCreateListOutput,
  fetchUserLeads: isFetchUserLeadsOutput,
  fetchUnprocessedLeads: isFetchUnprocessedLeadsOutput,
  getLeadsCampaign: isGetLeadsCampaignOutput,
  deleteDiscoveryTarget: isDeleteDiscoveryTargetOutput,
  createCreditsCheckoutSession: isCreditsCheckoutSessionOutput,
  fetchUserCredits: isFetchUserCreditsOutput
};
