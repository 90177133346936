import {Box, chakra, Flex, Text} from "@chakra-ui/react";
import React, {useContext, useMemo} from "react";
import {Link as RouterLink} from "react-router-dom";

import {ReactComponent as ChromeIcon} from "../../../assets/icons/chrome-installation-success.svg";
import {DsmSolidButton} from "../../../components/buttons";
import InnerAppLayout from "../../../components/inner-app-layout";
import routes from "../../../router/routes";
import Layout from "../components/layout";

import {BlurredBackground, OutlinedBox} from "src/components";
import {UserAuthContext} from "src/contexts";
import {colorHex, Heading} from "@workspace/ui";

const Link = chakra(RouterLink);
const ChromeExtInstallationSuccess: React.FC = () => {
  const userAuth = useContext(UserAuthContext);

  const callToActionRoute = useMemo(
    () => (userAuth ? routes.Integrations.route : routes.CreateAccount.route),
    [userAuth],
  );

  return (
    <InnerAppLayout showTrialInfo>
      <Layout gridTemplateColumns="1fr minmax(auto, 564px) 1fr">
        <Box position="relative" display="inline-block">
          <BlurredBackground colors={["#DF574B", "#FFCF48", "#22A565"]} />
          <OutlinedBox m="8" bg="white" zIndex="2" position="relative">
            <Flex mb="8" justifyContent="center">
              <ChromeIcon />
            </Flex>
            <Heading as="h1" variant="h3" color={colorHex.gray900} mb={4}>
              Chrome Extension Installed
            </Heading>
            <Text mb={8} fontSize="baseMinor" color={colorHex.gray900}>
              Now you can setup your social profiles and send messages to scale your
              business
            </Text>
            <Link to={callToActionRoute}>
              <DsmSolidButton>Continue</DsmSolidButton>
            </Link>
          </OutlinedBox>
        </Box>
      </Layout>
    </InnerAppLayout>
  );
};

export default ChromeExtInstallationSuccess;
